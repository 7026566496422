import './TournamentCommons.scss';

import maps from '../../assets/mappa.jpg';

function TournamentMaps() {
  return (
    <div className="tournament-maps">
      <img src={maps} alt="Mappa campi" />
    </div>
  );
}

export default TournamentMaps;
