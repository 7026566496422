import Form from '@rjsf/core';
import validator from '@rjsf/validator-ajv8';
import classNames from 'classnames';
import {useState} from 'react';

import './JsonSchemaForm.scss';

export default function JsonSchemaForm({formRef, schema, onSubmit, onError, uiSchema, initialFormData}) {
  const [formData, setFormData] = useState(initialFormData);

  const handleOnChange = async (props) => {
    setFormData(props.formData);
  };

  const renderButton = (kind, props) => {
    let label;
    switch (kind) {
      case 'add':
        label = 'Aggiungi';
        break;
      case 'remove':
        label = 'Rimuovi';
        break;
      case 'submit':
        label = 'Invia';
        break;
      default:
    }
    return (
      label && (
        <button
          type={kind === 'submit' ? 'submit' : 'button'}
          className={classNames('btn', 'btn-' + kind)}
          disabled={props.disabled}
          onClick={props.onClick}
        >
          {label}
        </button>
      )
    );
  };

  let _schema = schema;
  if (typeof schema === 'string') {
    _schema = JSON.parse(schema);
  }

  let _uiSchema = uiSchema;
  if (typeof uiSchema === 'string') {
    _uiSchema = JSON.parse(uiSchema);
  }

  return (
    <div className="json-schema-form">
      <Form
        ref={formRef}
        schema={_schema}
        uiSchema={_uiSchema}
        validator={validator}
        onChange={handleOnChange}
        onSubmit={onSubmit}
        onError={onError}
        formData={formData}
        showErrorList="top"
        templates={{
          ButtonTemplates: {
            AddButton: (props) => renderButton('add', props),
            CopyButton: (props) => renderButton('copy', props),
            MoveDownButton: (props) => renderButton('move-down', props),
            MoveUpButton: (props) => renderButton('move-up', props),
            RemoveButton: (props) => renderButton('remove', props),
            SubmitButton: (props) => renderButton('submit', props),
          },
        }}
      />
    </div>
  );
}
