import ErrorMessage from '../components/ErrorMessage';
import RainbowLayout from '../layouts/RainbowLayout';

export default function Page404() {
  return (
    <RainbowLayout>
      <div className="page-404">
        <ErrorMessage message="Non c'è niente qui" icon="notfound" />
      </div>
    </RainbowLayout>
  );
}
