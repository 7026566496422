import {PayPalButtons, PayPalScriptProvider} from '@paypal/react-paypal-js';
import React, {useState} from 'react';

import CheckoutButtons from './CheckoutButtons';
import ErrorMessage from './ErrorMessage';

// sandbox email: sb-arptp25802261@personal.example.com
// sandbox password: Z-;b&S]0

export default function PaypalCheckout({clientId, orderId, payment, doPaymentUpdate, handleRestart}) {
  const initialOptions = {
    'client-id': clientId,
    'enable-funding': 'venmo',
    'disable-funding': '',
    currency: 'EUR',
    'data-page-type': 'product-details',
    components: 'buttons',
    'data-sdk-integration-source': 'developer-studio',
  };

  const [errorMessage, setErrorMessage] = useState(null);

  return (
    <div className="paypal-checkout">
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          style={{
            shape: 'rect',
            layout: 'vertical',
            color: 'gold',
            label: 'paypal',
          }}
          createOrder={() => orderId}
          onApprove={async (data, actions) => {
            try {
              const response = await fetch(`/api/payment/${payment._id}/capture`, {
                method: 'POST',
                body: JSON.stringify({
                  token: payment.token,
                  orderId,
                }),
                headers: {
                  'Content-Type': 'application/json',
                },
              });

              const result = await response.json();
              if (response.status === 200) {
                doPaymentUpdate(result.payment);
              } else if (response.status === 402) {
                setErrorMessage(result.message);
                actions.restart();
              } else {
                setErrorMessage(result.message);
              }
            } catch (err) {
              setErrorMessage(err.message);
            }
          }}
        />
      </PayPalScriptProvider>

      <div className="payment-checkout">
        <CheckoutButtons cancelTitle="Indietro" handleCancel={handleRestart} />

        <ErrorMessage message={errorMessage} />
      </div>
    </div>
  );
}
