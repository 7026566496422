import classNames from 'classnames';
import {useEffect, useRef, useState} from 'react';

import logoArcigay from '../assets/logo.webp';
import logo from '../assets/riminirainbowcup.png';
import ErrorMessage from '../components/ErrorMessage';
import './TournamentPage.scss';
import TournamentFields from './tournament/TournamentFields';
import TournamentLeaderboard from './tournament/TournamentLeaderboard';
import TournamentMaps from './tournament/TournamentMaps';
import TournamentMatches from './tournament/TournamentMatches';
import TournamentNews from './tournament/TournamentNews';
import TournamentTeams from './tournament/TournamentTeams';

function TournamentPage({permalink}) {
  const [tournamentInfo, setTournamentInfo] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [activeView, setActiveView] = useState(null);
  const [settings, setSettings] = useState({selectedTeam: '', filterTeamMatches: false});
  const [isSettingsModalOpen, setSettingsModalOpen] = useState(false);
  const interval = useRef();
  const refreshInterval = useRef();

  const view = {
    leaderboard: <TournamentLeaderboard tournamentInfo={tournamentInfo} settings={settings} />,
    teams: <TournamentTeams tournamentInfo={tournamentInfo} settings={settings} />,
    matches: <TournamentMatches tournamentInfo={tournamentInfo} settings={settings} />,
    fields: <TournamentFields tournamentInfo={tournamentInfo} settings={settings} />,
    maps: <TournamentMaps />,
    news: <TournamentNews />,
    null: <div>Loading..</div>,
  }[activeView];

  const fetchTournamentInfo = async (changeView) => {
    try {
      const res = await fetch(`/api/tournament/${permalink}/info`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      });

      const result = await res.json();
      if (res.status < 400) {
        setTournamentInfo(result);
        if (!activeView && changeView) {
          setActiveView('leaderboard');
        }
      } else {
        setErrorMessage(result.message);
      }
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  useEffect(() => {
    fetchTournamentInfo(true);

    const url = new URLSearchParams(document.location.search);

    if (!refreshInterval.current) {
      refreshInterval.current = setInterval(() => fetchTournamentInfo(false), 10000);
    }

    if (url.has('monitor')) {
      function handleTimer() {
        let lastScrollInterval;

        interval.current = setInterval(function () {
          if (lastScrollInterval) {
            clearInterval(lastScrollInterval);
          }

          document.body.scrollTop = document.documentElement.scrollTop = 0;

          setActiveView((prev) => {
            switch (prev) {
              case 'leaderboard':
                return 'matches';
              case 'matches':
                return 'fields';
              case 'fields':
                return 'leaderboard';
              default:
                return prev;
            }
          });

          setTimeout(() => {
            lastScrollInterval = setInterval(() => {
              window.scrollBy({
                top: 10,
              });
            }, 10);
          }, 5000);
        }, 30000);
      }

      if (!interval.current) {
        handleTimer();
      }
    }
  }, [activeView]);

  return (
    <div className="tournament-page">
      <div className="tournament-header">
        <img src={logo} alt="Logo" />
      </div>

      <div className="tournament-content">
        <div className="tournament-menu">
          <ul>
            <li>
              <button
                type="button"
                className={classNames({red: true, selected: activeView === 'leaderboard'})}
                onClick={() => setActiveView('leaderboard')}
              >
                Classifica
              </button>
            </li>
            <li>
              <button
                type="button"
                className={classNames({orange: true, selected: activeView === 'matches'})}
                onClick={() => setActiveView('matches')}
              >
                Partite
              </button>
            </li>
            <li>
              <button
                type="button"
                className={classNames({yellow: true, selected: activeView === 'fields'})}
                onClick={() => setActiveView('fields')}
              >
                Campi
              </button>
            </li>
            <li>
              <button
                type="button"
                className={classNames({green: true, selected: activeView === 'teams'})}
                onClick={() => setActiveView('teams')}
              >
                Squadre
              </button>
            </li>
            <li>
              <button
                type="button"
                className={classNames({blue: true, selected: activeView === 'maps'})}
                onClick={() => setActiveView('maps')}
              >
                Mappa
              </button>
            </li>
            <li>
              <button
                type="button"
                className={classNames({violet: true, selected: activeView === 'news'})}
                onClick={() => setActiveView('news')}
              >
                Notizie
              </button>
            </li>
          </ul>
        </div>

        {view}
        <ErrorMessage message={errorMessage} />
      </div>

      <div className="tournament-footer" id="page-footer">
        <img src={logoArcigay} alt="Logo Arcigay Rimini" />
      </div>

      <div className="tournament-team">
        <div className="settings-text" onClick={() => setSettingsModalOpen(true)}>
          FILTRA
        </div>
      </div>

      <div className={classNames({'tournament-settings': true, 'modal-open': isSettingsModalOpen})}>
        <div className="settings-container">
          <div className="settings-header">Impostazioni squadra</div>

          <div className="settings-content">
            <div className="teams-select">
              <p>Evidenzia la tua squadra:</p>
              <select
                value={settings.selectedTeam}
                onChange={(e) => setSettings({...settings, selectedTeam: e.target.value})}
              >
                {[{_id: '', name: 'Tutte le squadre'}].concat(tournamentInfo?.teams || []).map((t) => (
                  <option value={t._id} key={t._id}>
                    {t.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="filter-checkbox">
              <input
                id="filter-team-checkbox"
                type="checkbox"
                checked={settings.filterTeamMatches}
                onChange={(e) => setSettings({...settings, filterTeamMatches: e.target.checked})}
              />
              <label for="filter-team-checkbox">Mostra solo le tue partite</label>
            </div>

            <div className="save-settings">
              <button type="button" onClick={() => setSettingsModalOpen(false)}>
                Salva preferenze
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TournamentPage;
