import {useEffect, useRef, useState} from 'react';
import {Form, Table} from 'react-bootstrap';

import CheckoutButtons from '../components/CheckoutButtons';
import ErrorMessage from '../components/ErrorMessage';
import Loader from '../components/Loader';
import RainbowLayout from '../layouts/RainbowLayout';
import './MembershipPage.scss';

export default function MembershipPage() {
  const [cardNumber, setCardNumber] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState('initial');
  const [cardInfo, setCardInfo] = useState(null);
  const [renewalId, setRenewalId] = useState(null);
  const [challenge, setChallenge] = useState(null);
  const [challengeValue, setChallengeValue] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const formRef = useRef();

  useEffect(() => {
    const effect = async function () {
      const url = new URLSearchParams(document.location.search);
      if (url.has('t') || url.has('e')) {
        let token;
        let method;
        if (url.has('t')) {
          token = url.get('t');
          method = 'telephone';
        } else {
          token = url.get('e');
          method = 'email';
        }

        const res = await fetch('/api/membership/renew', {
          method: 'POST',
          body: JSON.stringify({token, method}),
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (res.status === 200) {
          const result = await res.json();
          setRenewalId(result.renewalId);
          setChallenge(result.challenge);
          setState('renew');

          return;
        } else if (res.status >= 400 && res.status < 500) {
          const result = await res.json();
          setErrorMessage(result.message);
        } else {
          setErrorMessage('Invalid request');
        }
      }

      setState('search');
    };

    effect();
  }, []);

  const checkForm = () => {
    setErrorMessage('');

    if (!formRef.current.checkValidity()) {
      formRef.current.reportValidity();
      return false;
    }

    return true;
  };

  const searchCard = async () => {
    if (!checkForm()) {
      return;
    }

    setIsLoading(true);

    const res = await fetch('/api/membership/verify', {
      method: 'POST',
      body: JSON.stringify({
        cardNumber,
        birthDate,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (res.status === 200) {
      const result = await res.json();
      setCardInfo(result);
      setState('view');
    } else {
      if (res.status === 404) {
        setErrorMessage('La tessera non esiste o la data non corrisponde');
      } else if (res.status >= 400 && res.status < 500) {
        const result = await res.json();
        setErrorMessage(result.message);
      } else {
        setErrorMessage('Invalid request');
      }
    }

    setIsLoading(false);
  };

  const renewCard = async () => {
    if (!checkForm()) {
      return;
    }

    setIsLoading(true);

    const res = await fetch('/api/membership/renew', {
      method: 'POST',
      body: JSON.stringify({cardNumber, birthDate}),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (res.status === 200) {
      const result = await res.json();
      setRenewalId(result.renewalId);
      setChallenge(result.challenge);
      setState('renew');
    } else if (res.status >= 400 && res.status < 500) {
      const result = await res.json();
      setErrorMessage(result.message);
    } else {
      setErrorMessage('Invalid request');
    }

    setIsLoading(false);
  };

  const nextChallenge = async () => {
    if (!checkForm()) {
      return;
    }

    setIsLoading(true);

    const res = await fetch('/api/membership/challenge', {
      method: 'POST',
      body: JSON.stringify({
        step: challenge.step,
        renewalId,
        ...getChallengeValues(),
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (res.status === 200) {
      const result = await res.json();
      if (result.challenge) {
        setChallenge(result.challenge);
        setChallengeValue('');
      } else {
        document.location = result.paymentUrl;
      }
    } else {
      if (res.status >= 400 && res.status < 500) {
        const result = await res.json();
        setErrorMessage(result.message);
      } else {
        setErrorMessage('Invalid request');
      }
    }

    setIsLoading(false);
  };

  const getChallengeData = () => {
    switch (challenge.step) {
      case 'ask_telephone':
        return {
          title: 'Il numero di telefono ci servirà per inviarti un SMS con il codice di verifica',
          inputText: 'Numero di cellulare',
          inputType: 'tel',
        };
      case 'verify_telephone_confirmation_code':
        return {
          title: 'Ti abbiamo inviato un codice di verifica sul numero che ci hai indicato',
          inputText: 'Codice di verifica',
          inputType: 'number',
        };
      case 'ask_email':
        return {
          title: "L'indirizzo e-mail ci servirà per inviarti il codice di verifica",
          inputText: 'E-mail',
          inputType: 'email',
        };
      case 'verify_email_confirmation_code':
        return {
          title: "Ti abbiamo inviato un codice di verifica all'indirizzo e-mail che ci hai indicato",
          inputText: 'Codice di verifica',
          inputType: 'number',
        };
      case 'verify_telephone':
        return {
          title: `Inserisci il numero di telefono che hai usato in fase di iscrizione. È questo, se ti può aiutare: ${challenge.hint}`,
          inputText: 'Numero di telefono',
          inputType: 'tel',
        };
      case 'verify_email':
        return {
          title: `Inserisci l'indirizzo e-mail che hai usato in fase di iscrizione. È questo, se ti può aiutare: ${challenge.hint}`,
          inputText: 'Indirizzo e-mail',
          inputType: 'email',
        };
      default:
    }
  };

  const getChallengeValues = () => {
    switch (challenge.step) {
      case 'ask_telephone':
      case 'verify_telephone':
        return {
          telephone: challengeValue,
        };
      case 'ask_email':
      case 'verify_email':
        return {
          email: challengeValue,
        };
      case 'verify_telephone_confirmation_code':
      case 'verify_email_confirmation_code':
        return {
          verificationCode: challengeValue,
        };
      default:
    }
  };

  let content;
  switch (state) {
    case 'initial':
      content = <Loader />;
      break;
    case 'search':
      content = (
        <Form onSubmit={(e) => e.preventDefault()} ref={formRef}>
          <Form.Group className="mb-3">
            <Form.Label>Numero tessera</Form.Label>
            <Form.Control type="number" value={cardNumber} onChange={(e) => setCardNumber(e.target.value)} required />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Data di nascita</Form.Label>
            <Form.Control type="date" value={birthDate} onChange={(e) => setBirthDate(e.target.value)} required />
          </Form.Group>

          <CheckoutButtons
            cancelTitle="Cancella"
            handleCancel={() => {
              setCardNumber('');
              setBirthDate('');
            }}
            handleSubmit={searchCard}
            submitTitle="Verifica"
            isLoading={isLoading}
          />
        </Form>
      );
      break;
    case 'view':
      content = (
        <Form onSubmit={(e) => e.preventDefault()} ref={formRef}>
          <p>
            <strong>Nome:</strong> {cardInfo.user}
          </p>
          <p>
            <strong>Organizzazione:</strong> {cardInfo.organization}
          </p>
          <p>
            <strong>Tessera valida:</strong> {cardInfo.cardValid ? 'Sì' : 'No'}
          </p>
          <p>
            <strong>Storico tessera</strong>
          </p>
          <Table border={1}>
            <thead>
              <tr>
                <th>Valida da</th>
                <th>Valida a</th>
                <th>Organizzazione</th>
              </tr>
            </thead>

            <tbody>
              {cardInfo.history.map((h, index) => (
                <tr key={index}>
                  <td>{h.fromDate}</td>
                  <td>{h.toDate}</td>
                  <td>{h.organization}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          <CheckoutButtons
            cancelTitle="Indietro"
            handleCancel={() => {
              setCardNumber('');
              setBirthDate('');
              setState('search');
            }}
            handleSubmit={renewCard}
            submitTitle="Rinnova"
            isLoading={isLoading}
            submitDisabled={cardInfo.cardValid}
          />
        </Form>
      );
      break;
    case 'renew':
      const challengeData = getChallengeData();
      content = (
        <Form onSubmit={(e) => e.preventDefault()} ref={formRef}>
          <p>{challengeData.title}</p>

          <Form.Group className="mb-3">
            <Form.Label>{challengeData.inputText}</Form.Label>
            <Form.Control
              type={challengeData.inputType || 'text'}
              value={challengeValue}
              onChange={(e) => setChallengeValue(e.target.value)}
              required
            />
          </Form.Group>

          <CheckoutButtons
            cancelTitle="Annulla"
            handleCancel={() => {
              setState('view');
              setChallengeValue('');
            }}
            handleSubmit={nextChallenge}
            submitTitle="Avanti"
            isLoading={isLoading}
          />
        </Form>
      );
      break;
    default:
  }

  return (
    <RainbowLayout>
      <div className="membership-page">
        <h2 className="title">Rinnovo Tessera Arcigay</h2>
        {content}
        <ErrorMessage message={errorMessage} />
      </div>
    </RainbowLayout>
  );
}
