import {useEffect, useState} from 'react';

import ErrorMessage from '../components/ErrorMessage';
import JsonSchemaForm from '../components/JsonSchemaForm';
import RainbowLayout from '../layouts/RainbowLayout';
import './LinkPage.scss';

export default function LinkPage({permalink}) {
  const [link, setLink] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const fetchLink = async () => {
    const res = await fetch(`/api/link/${permalink}`, {
      method: 'GET',
    });

    if (res.status === 200) {
      const result = await res.json();
      setLink(result);
    } else if (res.status === 403) {
      setErrorMessage('Link non trovato');
    }
  };

  const onSubmit = async ({formData}, e) => {
    const res = await fetch(`/api/link/${permalink}`, {
      method: 'POST',
      body: JSON.stringify({fields: formData}),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (res.status === 200) {
      const result = await res.json();
      document.location = result.paymentUrl;
    } else {
      if (res.status >= 400 && res.status < 500) {
        const result = await res.json();
        setErrorMessage(result.message);
      } else {
        setErrorMessage('Invalid request');
      }
    }
  };

  useEffect(() => {
    fetchLink();
  }, []);

  return (
    <RainbowLayout>
      <div className="link-page">
        {link && <JsonSchemaForm onSubmit={onSubmit} schema={JSON.parse(link.fieldsSchema)} />}
        <ErrorMessage message={errorMessage} icon={errorMessage === 'Link non trovato' && 'notfound'} />
      </div>
    </RainbowLayout>
  );
}
