import classNames from 'classnames';

import './TournamentCommons.scss';

function TournamentLeaderboard({tournamentInfo, settings}) {
  const {rounds, leaderboards, teams} = tournamentInfo;

  return (
    <div className="tournament-leaderboard">
      <div className="table-container">
        <div className="table-row">
          {rounds.map((round) => (
            <div className="table-cell cell-50" key={round._id}>
              <div className="cell-container">
                <div className="cell-header">
                  <span>Girone: </span>
                  {round.name}
                </div>
                <div className="cell-content">
                  <div
                    className={classNames({
                      'round-leaderboard': true,
                      selected: leaderboards[round._id].some((l) => l.teamId === settings.selectedTeam),
                    })}
                  >
                    <table>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Squadra</th>
                          <th>Vinte</th>
                          <th>Perse</th>
                          <th>Giocate</th>
                          <th>Totali</th>
                          <th>Diff. punti</th>
                        </tr>
                      </thead>
                      <tbody>
                        {leaderboards[round._id].map((l, index) => (
                          <tr
                            key={l.teamId}
                            className={classNames({odd: index % 2 === 1, selected: l.teamId === settings.selectedTeam})}
                          >
                            <td>{index + 1}</td>
                            <td>{teams.find((t) => t._id === l.teamId)?.name}</td>
                            <td>{l.wins}</td>
                            <td>{l.loses}</td>
                            <td>{l.played}</td>
                            <td>{l.total}</td>
                            <td>{l.scoreDifference}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TournamentLeaderboard;
