import {useEffect, useState} from 'react';

import Checkout from '../components/Checkout.jsx';
import ErrorMessage from '../components/ErrorMessage.jsx';
import Loader from '../components/Loader.jsx';
import PaymentResult from '../components/PaymentResult.jsx';
import RainbowLayout from '../layouts/RainbowLayout.jsx';

export default function PaymentPage({paymentId}) {
  const [payment, setPayment] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const doPaymentUpdate = (payment) => {
    if (payment) {
      setPayment(payment);
    } else {
      fetchPayment();
    }
  };

  const fetchPayment = async () => {
    try {
      const url = new URL(document.location);
      const token = url.searchParams.get('token');

      const res = await fetch(`/api/payment/${paymentId}?token=${token}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      });

      const result = await res.json();
      if (res.status === 200) {
        setPayment(result);
      } else {
        setErrorMessage(result.message);
      }
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  useEffect(() => {
    setTimeout(fetchPayment, 2000);
  }, []);

  let content;
  if (payment) {
    if (payment.status === 'pending') {
      content = <Checkout payment={payment} doPaymentUpdate={doPaymentUpdate} />;
    } else if (['completed', 'errored', 'canceled'].includes(payment.status)) {
      content = <PaymentResult payment={payment} />;
    }
  } else {
    content = <Loader />;
  }

  return (
    <RainbowLayout>
      <div className="payment">
        {content}
        <ErrorMessage message={errorMessage} />
      </div>
    </RainbowLayout>
  );
}
