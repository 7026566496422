import 'bootstrap/dist/css/bootstrap.min.css';
import {useEffect, useState} from 'react';

import './App.scss';
import Page404 from './views/Page404.jsx';
import LinkPage from './views/LinkPage.jsx';
import MembershipPage from './views/MembershipPage.jsx';
import ModulePage from './views/ModulePage.jsx';
import PaymentPage from './views/PaymentPage.jsx';
import TournamentPage from './views/TournamentPage.jsx';

const router = {
  payment: {
    regex: /^\/payment\/([a-f0-9]{24})$/,
    render: (match) => <PaymentPage paymentId={match[1]} />,
  },
  module: {
    regex: /^\/module\/([\w]+)$/,
    render: (match) => <ModulePage permalink={match[1]} />,
  },
  link: {
    regex: /^\/l\/([\w]+)$/,
    render: (match) => <LinkPage permalink={match[1]} />,
  },
  membership: {
    regex: /^\/rinnova/,
    render: (match) => <MembershipPage />,
  },
  tournament: {
    regex: /^\/tournament\/([\w]+)$/,
    render: (match) => <TournamentPage permalink={match[1]} />,
  },
  default: {
    regex: /.*/,
    render: () => <Page404 />,
  },
};

function App() {
  const [currentPage, setCurrentPage] = useState(null);

  useEffect(() => {
    for (const [page, opts] of Object.entries(router)) {
      const match = opts.regex.exec(document.location.pathname);

      if (match) {
        setCurrentPage({name: page, match});
        break;
      }
    }
  }, []);

  return <div className="app">{currentPage && router[currentPage.name].render(currentPage.match)}</div>;
}

export default App;
