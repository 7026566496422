import {ExpressCheckoutElement, useElements, useStripe} from '@stripe/react-stripe-js';
import React, {useState} from 'react';

import CheckoutButtons from './CheckoutButtons';
import ErrorMessage from './ErrorMessage';
import './StripeCheckoutWallet.scss';

export default function CheckoutWallet({clientSecret, handleRestart}) {
  const stripe = useStripe();
  const elements = useElements();
  const [visibility, setVisibility] = useState('hidden');
  const [errorMessage, setErrorMessage] = useState();

  const onReady = ({availablePaymentMethods}) => {
    if (!availablePaymentMethods) {
      console.log(availablePaymentMethods);
    } else {
      setVisibility('initial');
    }
  };

  const onConfirm = async (event) => {
    if (!stripe) {
      return;
    }

    const submitResult = await elements.submit();
    if (submitResult.error) {
      setErrorMessage(submitResult.error.message);
      return;
    }

    const paymentResult = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: document.location.toString(),
      },
    });

    if (paymentResult.error) {
      setErrorMessage(paymentResult.error.message);
    } else {
      setErrorMessage(null);
    }
  };

  return (
    <div className="stripe-checkout-wallet">
      <div className="stripe-checkout-element" style={{visibility}}>
        <ExpressCheckoutElement onConfirm={onConfirm} onReady={onReady} />
      </div>

      <div className="payment-checkout">
        <CheckoutButtons cancelTitle="Indietro" handleCancel={handleRestart} />
      </div>

      <ErrorMessage message={errorMessage} />
    </div>
  );
}
