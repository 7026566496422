import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {useState} from 'react';

import ApplePayLogo from '../assets/applepay.svg';
import GooglePayLogo from '../assets/googlepay.svg';
import MastercardLogo from '../assets/mastercard.svg';
import PaypalLogo from '../assets/paypal.svg';
import SatispayLogo from '../assets/satispay.svg';
import VisaLogo from '../assets/visa.svg';
import './Checkout.scss';
import CheckoutButtons from './CheckoutButtons';
import ErrorMessage from './ErrorMessage';
import PaypalCheckout from './PaypalCheckout';
import CheckoutCard from './StripeCheckoutCard';
import CheckoutWallet from './StripeCheckoutWallet';

function Checkout({payment, doPaymentUpdate}) {
  const [paymentKind, setPaymentKind] = useState('');
  const [paypalClientId, setPaypalClientId] = useState(null);
  const [paypalOrderId, setPaypalOrderId] = useState(null);
  const [stripeClientSecret, setStripeClientSecret] = useState(null);
  const [stripeClient, setStripeClient] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleRestart = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    setPaypalClientId(null);
    setPaypalOrderId(null);
    setStripeClientSecret(null);

    try {
      const response = await fetch(`/api/payment/${payment._id}/restart`, {
        method: 'POST',
        body: JSON.stringify({
          token: payment.token,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status !== 200) {
        const error = await response.json();
        setErrorMessage(error.message || 'Failed to restart payment');
      }
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(`/api/payment/${payment._id}`, {
        method: 'DELETE',
        body: JSON.stringify({
          token: payment.token,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        if (payment.callbackUrl) {
          document.location = payment.callbackUrl;
        } else {
          doPaymentUpdate();
        }
      } else {
        const error = await response.json();
        setErrorMessage(error.message || 'Failed to cancel payment');
      }
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(`/api/payment/${payment._id}/checkout`, {
        method: 'POST',
        body: JSON.stringify({
          token: payment.token,
          paymentKind,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      if (response.status === 200) {
        if (paymentKind === 'satispay') {
          document.location = data.paymentUrl;
        } else if (paymentKind === 'paypal') {
          setPaypalClientId(data.clientId);
          setPaypalOrderId(data.orderId);
        } else if (paymentKind === 'card' || paymentKind === 'wallet') {
          setStripeClientSecret(data.clientSecret);
          setStripeClient(loadStripe(data.publicKey));
        }
      } else {
        setErrorMessage(data.message);
      }
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const selectPaymentKind = (value) => {
    return (e) => {
      setPaymentKind(value);
    };
  };

  if (paypalClientId && paypalOrderId) {
    return (
      <PaypalCheckout
        clientId={paypalClientId}
        orderId={paypalOrderId}
        payment={payment}
        doPaymentUpdate={doPaymentUpdate}
        handleRestart={handleRestart}
      />
    );
  }

  if (stripeClientSecret) {
    const appearance = {
      theme: 'stripe',
    };
    /** @type {StripeElementsOptions} */
    const options = {
      clientSecret: stripeClientSecret,
      appearance,
    };

    let Element;
    if (paymentKind === 'card') {
      Element = CheckoutCard;
    } else {
      Element = CheckoutWallet;
    }

    return (
      <Elements options={options} stripe={stripeClient}>
        <Element clientSecret={stripeClientSecret} handleRestart={handleRestart} />
      </Elements>
    );
  }

  return (
    <div className="checkout">
      <header>
        <span>Stai pagando</span>
      </header>

      <div className="payment-amount">
        <div className="amount-value">{(payment.amount / 100).toFixed(2).toString().replace('.', ',')}</div>
        <div className="amount-currency">EURO</div>
      </div>

      <div className="payment-methods">
        <ul>
          <li
            className={'satispay' + (paymentKind === 'satispay' ? ' selected' : '')}
            onClick={selectPaymentKind('satispay')}
          >
            <img src={SatispayLogo} alt="Satispay" />
          </li>
          <li
            className={'paypal' + (paymentKind === 'paypal' ? ' selected' : '')}
            onClick={selectPaymentKind('paypal')}
          >
            <img src={PaypalLogo} alt="Paypal" />
          </li>
          <li className={'cards' + (paymentKind === 'card' ? ' selected' : '')} onClick={selectPaymentKind('card')}>
            <img src={MastercardLogo} alt="Mastercard" />
            <img src={VisaLogo} alt="Visa" />
          </li>
          <li
            className={'wallet' + (paymentKind === 'wallet' ? ' selected' : '')}
            onClick={selectPaymentKind('wallet')}
          >
            <img className="googlepay-logo" src={GooglePayLogo} alt="Google Pay" />
            <img className="applepay-logo" src={ApplePayLogo} alt="Apple Pay" />
          </li>
        </ul>
      </div>

      {payment.environment === 'sandbox' && <div className="test-environment">Test environment</div>}

      <div className="payment-checkout">
        <CheckoutButtons
          cancelTitle={payment.cancellable && 'Annulla'}
          handleCancel={handleCancel}
          submitTitle="Procedi"
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          submitDisabled={isLoading || !paymentKind}
        />

        <ErrorMessage message={errorMessage} />
      </div>
    </div>
  );
}

export default Checkout;
