import {useEffect, useState} from 'react';
import Markdown from 'react-markdown';

import CheckoutButtons from '../components/CheckoutButtons.jsx';
import ErrorMessage from '../components/ErrorMessage.jsx';
import JsonSchemaForm from '../components/JsonSchemaForm.jsx';
import Loader from '../components/Loader.jsx';
import RainbowLayout from '../layouts/RainbowLayout.jsx';
import './ModulePage.scss';

export default function ModulePage({permalink}) {
  const [moduleForm, setModuleForm] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [state, setState] = useState('initial');
  const [formData, setFormData] = useState({});

  const getModuleForm = async () => {
    const res = await fetch(`/api/module/${permalink}`, {
      method: 'GET',
    });

    if (res.status === 200) {
      const form = await res.json();
      if (!form.initialMarkdown) {
        setState('progress');
      }

      setModuleForm(form);
      setErrorMessage(null);
    } else if (res.status === 404) {
      setErrorMessage('Modulo non trovato');
    } else if (res.status === 403) {
      const error = await res.json();
      if (error.code === 'MODULE_DISABLED') {
        setErrorMessage('Modulo disabilitato');
      } else if (error.code === 'MODULE_EXPIRED') {
        setErrorMessage('Modulo non più attivo');
      } else {
        setErrorMessage(error.message);
      }
    } else {
      setErrorMessage('Unhandled error');
    }
  };

  const onSubmit = async ({formData}, e) => {
    setState('loading');
    setFormData(formData);
    setErrorMessage(null);

    const res = await fetch(`/api/module/${permalink}`, {
      method: 'POST',
      body: JSON.stringify({answer: formData}),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (res.status === 200) {
      setState('complete');
      setErrorMessage(null);
    } else if (res.status >= 400 && res.status < 500) {
      setState('progress');
      const error = await res.json();
      setErrorMessage(error.reason || error.message);
    } else {
      setErrorMessage('Unhandled error');
    }
  };

  useEffect(() => {
    getModuleForm();

    const handleBeforeUnload = (event) => {
      if (state === 'progress') {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  let component;
  if (moduleForm) {
    if (state === 'initial') {
      component = (
        <div className="form-initial">
          <h1 className="module-title">{moduleForm.title}</h1>
          <div className="module-initial-content">
            <Markdown>{moduleForm.initialMarkdown}</Markdown>
          </div>
          <CheckoutButtons submitTitle="Avanti" handleSubmit={() => setState('progress')} />
        </div>
      );
    } else if (state === 'progress') {
      component = (
        <JsonSchemaForm
          onSubmit={onSubmit}
          schema={moduleForm.inputSchema}
          uiSchema={moduleForm.uiSchema}
          initialFormData={formData}
        />
      );
    } else if (state === 'complete') {
      component = (
        <div className="form-complete">
          <h1 className="module-completed-title">Risposta inviata con successo!</h1>
          <Markdown>{moduleForm.completeMarkdown}</Markdown>
        </div>
      );
    } else if (state === 'loading') {
      component = <Loader />;
    }
  }

  return (
    <RainbowLayout>
      <div className="module-page">
        {component}
        <ErrorMessage message={errorMessage} icon={state === 'initial' && 'notfound'} />
      </div>
    </RainbowLayout>
  );
}
