import NotFoundLottie from '../assets/notfound.lottie';
import './ErrorMessage.scss';

export default function ErrorMessage({message, icon}) {
  if (!message) {
    return null;
  }

  let animation;
  if (icon === 'notfound') {
    animation = NotFoundLottie;
  }

  return (
    <div className="error-message">
      {animation && (
        <dotlottie-player src={animation} background="transparent" speed="1" autoplay loop></dotlottie-player>
      )}
      <p>{message}</p>
    </div>
  );
}
