import classNames from 'classnames';

import './TournamentCommons.scss';

function TournamentFields({tournamentInfo, settings}) {
  const {locations, matches, teams} = tournamentInfo;

  return (
    <div className="tournament-fields">
      <div className="table-container">
        <div className="table-row">
          {locations.map((location) => (
            <div className="table-cell cell-33" key={location._id}>
              <div className="cell-container">
                <div className="cell-header">
                  <span>Campo: </span>
                  {location.name}
                </div>
                <div className="cell-content">
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Squadra A</th>
                        <th>Squadra B</th>
                        <th>Arbitra</th>
                        <th>Inizio</th>
                      </tr>
                    </thead>
                    <tbody>
                      {matches
                        .filter((m) => m.location === location._id)
                        .filter((m) => {
                          if (settings.selectedTeam && settings.filterTeamMatches) {
                            return (
                              m.teamA === settings.selectedTeam ||
                              m.teamB === settings.selectedTeam ||
                              m.referee === settings.selectedTeam
                            );
                          }

                          return true;
                        })
                        .map((m, index) => (
                          <tr
                            key={m._id}
                            className={classNames({
                              odd: index % 2 === 1,
                              selected:
                                !settings.filterTeamMatches &&
                                (m.teamA === settings.selectedTeam ||
                                  m.teamB === settings.selectedTeam ||
                                  m.referee === settings.selectedTeam),
                            })}
                          >
                            <td>{m.ref}</td>
                            <td className={classNames({strong: m.teamA === settings.selectedTeam})}>
                              {teams.find((t) => t._id === m.teamA)?.name}
                            </td>
                            <td className={classNames({strong: m.teamB === settings.selectedTeam})}>
                              {teams.find((t) => t._id === m.teamB)?.name}
                            </td>
                            <td className={classNames({strong: m.referee === settings.selectedTeam})}>
                              {teams.find((t) => t._id === m.referee)?.name}
                            </td>
                            <td>{m.startTime}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TournamentFields;
