import classNames from 'classnames';

import './CheckoutButtons.scss';

export default function CheckoutButtons({
  cancelTitle,
  handleCancel,
  submitTitle,
  handleSubmit,
  isLoading,
  submitDisabled,
}) {
  return (
    <div className="checkout-buttons">
      {cancelTitle && (
        <button
          className={classNames({button: true, 'cancel-button': true, 'full-width': !submitTitle})}
          type="submit"
          onClick={handleCancel}
        >
          {cancelTitle}
        </button>
      )}

      {submitTitle && (
        <button
          className={classNames({button: true, 'submit-button': true, 'full-width': !cancelTitle})}
          type="submit"
          disabled={submitDisabled}
          onClick={handleSubmit}
        >
          {isLoading ? <div className="spinner" id="spinner"></div> : submitTitle}
        </button>
      )}
    </div>
  );
}
