import classNames from 'classnames';

import './TournamentCommons.scss';
import flag from '../../assets/flag.svg';
import hourglass from '../../assets/hourglass.svg';

function TournamentMatches({tournamentInfo, settings}) {
  const {locations, matches, teams} = tournamentInfo;

  const statusIcon = (match) => {
    if (match.status === 'inprogress') {
      return <img src={hourglass} alt="In progress" />;
    } else if (match.status === 'terminated') {
      return <img src={flag} alt="Terminata" />;
    }

    return null;
  }

  return (
    <div className="tournament-matches">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Stato</th>
            <th>Squadra A</th>
            <th>Punti A</th>
            <th>Punti B</th>
            <th>Squadra B</th>
            <th>Campo</th>
            <th>Arbitra</th>
            <th>Inizio</th>
          </tr>
        </thead>
        <tbody>
          {matches
            .filter((m) => {
              if (settings.selectedTeam && settings.filterTeamMatches) {
                return (
                  m.teamA === settings.selectedTeam ||
                  m.teamB === settings.selectedTeam ||
                  m.referee === settings.selectedTeam
                );
              }

              return true;
            })
            .map((m, index) => (
              <tr
                key={m._id}
                className={classNames({
                  odd: index % 2 === 1,
                  selected:
                    !settings.filterTeamMatches &&
                    (m.teamA === settings.selectedTeam ||
                      m.teamB === settings.selectedTeam ||
                      m.referee === settings.selectedTeam),
                })}
              >
                <td>{m.ref}</td>
                <td>{statusIcon(m)}</td>
                <td className={classNames({strong: m.teamA === settings.selectedTeam})}>
                  {teams.find((t) => t._id === m.teamA)?.name}
                </td>
                <td>{m.scoreA || ''}</td>
                <td>{m.scoreB || ''}</td>
                <td className={classNames({strong: m.teamB === settings.selectedTeam})}>
                  {teams.find((t) => t._id === m.teamB)?.name}
                </td>
                <td>{locations.find((l) => l._id === m.location)?.name}</td>
                <td className={classNames({strong: m.referee === settings.selectedTeam})}>
                  {teams.find((t) => t._id === m.referee)?.name}
                </td>
                <td>{m.startTime}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default TournamentMatches;
