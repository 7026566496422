import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import React, {useState} from 'react';

import CheckoutButtons from './CheckoutButtons';
import ErrorMessage from './ErrorMessage';
import Loader from './Loader';
import './StripeCheckoutForm.scss';

export default function CheckoutCard({clientSecret, handleRestart}) {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    setErrorMessage(null);

    const {submitError} = await elements.submit();
    if (submitError) {
      setIsLoading(false);
      setErrorMessage(submitError.message);

      return;
    }

    const {error} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: document.location.toString(),
      },
      clientSecret,
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setErrorMessage(error.message);
    } else {
      setErrorMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: 'tabs',
  };

  return (
    <form className="stripe-checkout-form">
      <div className="stripe-checkout-element">
        <PaymentElement id="payment-element" options={paymentElementOptions} onReady={() => setIsReady(true)} />
      </div>

      {isReady ? (
        <CheckoutButtons
          cancelTitle="Indietro"
          handleCancel={handleRestart}
          submitTitle="Paga ora"
          handleSubmit={handleSubmit}
          submitDisabled={isLoading || !stripe || !elements}
          isLoading={isLoading}
        />
      ) : (
        <Loader />
      )}

      <ErrorMessage message={errorMessage} />
    </form>
  );
}
