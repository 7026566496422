import classNames from 'classnames';

import './TournamentCommons.scss';

function TournamentTeams({tournamentInfo, settings}) {
  const {teams} = tournamentInfo;

  return (
    <div className="tournament-teams">
      <div className="table-container">
        <div className="table-row">
          {teams.map((team) => (
            <div className="table-cell cell-12" key={team._id}>
              <div className="table-container">
                <div className={classNames({'team-cell': 1, selected: team._id === settings.selectedTeam})}>
                  <div className="team-name">{team.name}</div>

                  <div className="team-players">
                    <ul>
                      {team.players.map((p) => (
                        <li className={classNames({referent: p.isReferent})}>{p.fullName}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TournamentTeams;
