import './TournamentCommons.scss';

function TournamentNews() {
  return (
    <div className="tournament-news">
      Nessuna notizia
    </div>
  );
}

export default TournamentNews;
