import logo from '../assets/logo.webp';
import './RainbowLayout.scss';

function RainbowLayout({children}) {
  return (
    <div className="rainbow">
      <div className="page-container">
        <header className="page-header">
          <img src={logo} alt="logo" />
        </header>

        <div className="page-content">{children}</div>

        <footer className="page-footer">
          <p>APS Alan Turing - Arcigay Rimini</p>
          <p>Via Covignano 238, 47923 Rimini RN</p>
          <p>C.F. 91046230404 - P.I. 04273150401</p>
          <p>
            Realizzato da <a href="https://eciavatta.dev">Emiliano Ciavatta</a>
          </p>
        </footer>
      </div>
    </div>
  );
}

export default RainbowLayout;
