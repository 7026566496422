import PaymentCompletedLottie from '../assets/payment-completed.lottie';
import PaymentErroredLottie from '../assets/payment-errored.lottie';
import ErrorMessage from './ErrorMessage';
import './PaymentResult.scss';

function PaymentResult({payment}) {
  let animationFile;
  let message;
  let errorMessage;

  if (payment.status === 'completed') {
    animationFile = PaymentCompletedLottie;
    message = 'Pagamento Completato';
  } else if (payment.status === 'errored') {
    animationFile = PaymentErroredLottie;
    message = 'Pagamento Fallito';
    errorMessage = payment.error?.message || 'Errore sconosciuto';
  } else if (payment.status === 'canceled') {
    animationFile = PaymentErroredLottie;
    message = 'Pagamento Cancellato';
  }

  return (
    <div className="payment-result">
      <div className="payment-animation">
        <dotlottie-player src={animationFile} background="transparent" speed="1" autoplay></dotlottie-player>
      </div>

      <h2 className="payment-message">{message}</h2>
      <ErrorMessage message={errorMessage} />
    </div>
  );
}

export default PaymentResult;
